import React from 'react';
import Helmet from 'react-helmet';
import { Link } from 'gatsby';

export default () => (
  <>
    <Helmet>
      <html lang="en" />
      <title>DAILY DEALS REPORT 2019&#174;</title>
      <meta
        name="description"
        content="Home of the best daily deals in 2019."
      />
    </Helmet>
    <h1>DAILY DEALS REPORT</h1>
    <ul>
      <li>
        <Link to="/">Home</Link>
      </li>
    </ul>
  </>
);
